import React, { useEffect, useState } from "react";
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade';
import LoadingTooth from "../components/loadingTooth/loadingTooth"
import CircleLink from "../components/link/circleLink"

const SeasonTitle = styled.h2`
  color: ${({ theme }) => theme.white};
  text-shadow: -1px -1px 0 ${({ theme }) => theme.primaryColor}, 1px -1px 0 ${({ theme }) => theme.primaryColor}, -1px 1px 0 ${({ theme }) => theme.primaryColor}, 1px 1px 0 ${({ theme }) => theme.primaryColor};
  font-weight: 600;
  text-align: left;
  font-size: 40px;
  z-index: 4;
  margin-bottom: 0;
  margin-left: 5vw;
  @media (max-width: 767px) {
    font-size: 30px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 31.33% 31.33% 31.33%;
  width: 90%;
  margin: 20px auto;
  grid-column-gap: 3%;
  column-gap: 3%;
  @media (max-width: 1420px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 767px) {
    display: block;
    width: 90%;
  }
`;

const WrapperVideo = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 10vh;
  position: relative;
  @media (max-width: 767px) {
    margin-bottom: 60px;
    text-align: center;
  }
  img {
    width: 100%;
    height: auto;
  }
  p{
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    padding: 0 30px;
    line-height: 25px;
    text-align: center;
    font-weight: 500;
    position: relative;
    top: 0;
    @media (max-width: 767px) {
      padding: 0;
    }
  }

`;

export default function Consigli() {
  const [seasonOne, setSeasonOne] = useState();
  const [seasonTwo, setSeasonTwo] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () =>  {
    // FETCH CHANNEL VIDEOS
    await fetch(`https://www.googleapis.com/youtube/v3/search?key=${process.env.GATSBY_YOUTUBE_API_KEY}&channelId=UCZIfMUzZ8sNJYXEaO0Y_rbA&part=snippet,id&order=date&maxResults=100`)
    .then(response => response.json())
    .then(response => {
      setSeasonOne(response.items.filter(video => video.snippet.title.substring(0, 2) === "S1"))
      setSeasonTwo(response.items.filter(video => video.snippet.title.substring(0, 2) === "S2"))
    });

    setLoading(false)
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Layout pageTitle="consigli - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
        <Fade left duration={1000}>
            <h1>Consigli</h1>
        </Fade>
        {
          loading ? (<LoadingTooth/>) : (
            <>
              <SeasonTitle>Stagione 2</SeasonTitle>
              <Container>
              {
                seasonTwo && seasonTwo.reverse().map((video, index) => (
                  <WrapperVideo>
                      <img key={`video_youtube_season2_${index}`} src={video.snippet.thumbnails.high.url} alt={video.snippet.title} />
                      <p
                        className="paragraph-social-box-image"
                        dangerouslySetInnerHTML={video.snippet.title ? {__html: video.snippet.title} : "0"}
                      />
                      <CircleLink
                        key={`youtube_video_${index}`}
                        href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                        target="_blank"
                        children="Guarda il Video"
                      />
                  </WrapperVideo>
                ))
              }
              </Container>
              <SeasonTitle>Stagione 1</SeasonTitle>
              <Container>
              {
                seasonOne && seasonOne.reverse().map((video, index) => (
                  <WrapperVideo>
                      <img key={`video_youtube_${index}`} src={video.snippet.thumbnails.high.url} alt={video.snippet.title} />
                      <p className="paragraph-social-box-image">
                        {video.snippet.title ? video.snippet.title : ""}
                      </p>
                      <CircleLink
                        key={`youtube_video_${index}`}
                        href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                        target="_blank"
                        children="Guarda il Video"
                      />
                  </WrapperVideo>
                ))
              }
              </Container>
            </>
          )
        }
    </Layout>
  )
}